import React from 'react';
import { Helmet } from 'react-helmet';
import '../../styles/template.css';
import '../../styles/contact.css';
import '../../styles/about.css';
import '../../styles/team.css';

import mail from '../../images/icons/mail.svg';
import playerGlobe from '../../images/player-globe.png';
import Layout from '../../components/Layout';

const Page = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Racqy - Contact</title>
      </Helmet>
      <main
        id="contact"
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',

          height: '76vh',
        }}
      >
        <div />
        {/* <section>
          <div className="page-content">
            <h1 style={{ textAlign: "center" }}>Contact support</h1>
          </div>
        </section> */}
        <section className="content-section contact-content-section">
          <div className="page-content page-content-contact">
            <div className="col">
              <h2 style={{ margin: '45px 0px' }}>Contact support</h2>
              <h1 style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Opening hours
              </h1>
              <p>Weekdays: 09-17</p>
              <p>Weekends & holidays: Closed</p>
              <div className="ruler" />

              <div
                className="links"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'stretch',

                  marginTop: '20px',
                }}
              >
                <a href="mailto:support@racqy.com">
                  <img src={mail} />
                  <span style={{ fontSize: '14px', marginLeft: '12px' }}>
                    support@racqy.com
                  </span>
                </a>
              </div>
            </div>
            <div className="col right-image">
              <img src={playerGlobe} />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Page;
